<template>
  <div class="hp-categories">
    <Carousel
        dir="rtl"
        ref="carousel"
        :breakpoints="breakpoints"
        v-model="slide"
        @drag="onDragStart"
        @slide-end="onDragEnd"
    >

      <Slide  v-for="(category,index) in hpCategories" :key="category?.title">
        <nuxt-link
            :to="parseLink(category?.cat?.url)"
            class="hp-cat d-flex align-items-end justify-content-center cover"
            :class="{ 'disabled-navigation': isDragging }"
            :style="`background-image: url(${category?.image?.node?.sourceUrl}.webp)`"

        >
          <div class="hp-cat__inner pb-5">

            <h3 class="title-h4 text--white">{{ category?.title }}</h3>
            <div class="subtitle text--white">{{ category?.subtitle }}</div>
            <div class="subtitle-bold text--red red-underline red-underline--short2">{{ category?.text }}</div>
          </div>
        </nuxt-link>

      </Slide>
    </Carousel>
  </div>
</template>

<script setup lang="ts">
const {currentPage} = useGlobalStore();
const {hpCategories} = currentPage?.template?.homepage;
const slide = ref(0);
const carousel = ref(null);
const isDragging = ref(false);

const settings = {
  itemsToShow: 4,
  snapAlign: "start",
  transition: 1000,
};
const breakpoints = {
  100: {
    snapAlign: "start",
    itemsToShow: 1.4,
    wrapAround: false,
  },
  800: {
    snapAlign: "start",
    wrapAround: false,
    itemsToShow: 4.5,
  },
};
const onDragStart = (event) => {
  console.log(event);
  isDragging.value = true;
  setTimeout(() => {
    isDragging.value = false;
  }, 500);
};

const onDragEnd = () => {
  isDragging.value = false;
};

</script>
<style lang="scss">
.hp-cat {
  @media screen and (max-width: 800px) {
    height: 325px;
  }

}
.disabled-navigation {
  pointer-events: none;
  cursor: not-allowed;
}
</style>
